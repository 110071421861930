const AssetClassTypes = {
    RURAL_ELECTRIFICATION_BONDS: 'RuralElectrificationBond',
    PERPETUAL_BONDS: 'PerpetualBond',
    MUNICIPAL_BONDS: 'MunicipalBond',
    TAX_FREE_BONDS: 'TaxFreeBond',
    STATE_DEVELOPMENT_LOAN: 'StateDevelopmentLoan',
    CAPITAL_GAINS: 'CapitalGains',
    PUBLIC_ISSUE: 'PublicIssue',
    PRIVATE_ISSUE: 'PrivateIssue',
};

Object.freeze(AssetClassTypes);

module.exports = AssetClassTypes;
