/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
//import '@ui/styles/globals.css';
import { getLayoutConfig } from '@components/Layout/LayoutConfiguration';
import { AuthProvider } from '@context/auth';
import { notInvestorPaths } from '@helpers/constants.js';
import useAuthStore from '@store/useAuthStore.js';
import '@styles/Homepage/style.css';
import '@styles/globals.css';
import 'aos/dist/aos.css';
import Aos from 'aos';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router.js';
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';

const ToastContainer = dynamic(() =>
    import('react-toastify').then((mod) => mod.ToastContainer)
);

const WithAuth = dynamic(() => import('@components/Auth/WithAuth'), {
    ssr: true,
    csr: true,
});

function MyApp({ Component, pageProps, router }) {
    let layoutConfig = getLayoutConfig(router.pathname);
    const { Layout: FetchedLayout, overrides } = layoutConfig;
    const { initialize } = useAuthStore();
    const nextRouter = useRouter();

    useEffect(() => {
        !notInvestorPaths.includes(layoutConfig?.path) &&
            initialize(nextRouter);
            Aos.init({ duration: 800, once: false });
    }, []);

    return (
        <>
            <WithAuth router={router}>
                {notInvestorPaths.includes(layoutConfig?.path) ? (
                    <FetchedLayout
                        children={<Component {...pageProps} />}
                        {...overrides}
                    />
                ) : (
                    <AuthProvider>
                        <FetchedLayout
                                children={<Component {...pageProps} />}
                                {...overrides}
                        />
                    </AuthProvider>
                )}
            </WithAuth>

            <ToastContainer />
        </>
    );
}

export default MyApp;
