import dynamic from 'next/dynamic';
import {
    useCallback,
    useEffect,
    useState,
    createContext,
    useMemo,
} from 'react';
import { useRouter } from 'next/router';
import { getInvestorRelationshipManagerDetails } from '@services/investors.service';
import {
    menuItems,
    sideBarNavItems,
} from '@components/Investor/NewDashboard/helper';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import useAuthStore from '@store/useAuthStore';
import { isAuthenticated } from '@services/identity.service';
import { showReferral } from '@helpers/utils/investor-utils';
const LoginModalV2 = dynamic(
    () => import('@components/Auth/LoginModal/LoginModalV2'),
    { ssr: false }
);

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const auth = cookies.get('AUTH');
    const entityType = auth?.entityType;
    const router = useRouter();
    const { isLogged, logout, investor: investorRecord } = useAuthStore();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const [menu, setMenu] = useState([]);
    const [rmDetails, setRMDetails] = useState(null);
    const [sideBarItems, setSideBarItems] = useState([]);
    const [investor, setInvestor] = useState({
        isWalletActive: false,
        investor: null,
    });

    const handleLogout = useCallback(() => {
        logout(router);
    }, [isLogged, router]);

    const showRMDetails = useMemo(
        () => entityType !== 'ifa' && rmDetails,
        [rmDetails]
    );

    // TODO: WALLET AND REFERRAL
    const checkKycStatus = useCallback(
        (authenticated) => {
            if (authenticated && investorRecord) {
                setInvestor((prev) => ({
                    ...prev,
                    investor: investorRecord,
                }));
                return investorRecord?.kycStatus === 'Verified';
            }
            return false;
        },
        [isLogged]
    );

    const fetchUserData = async () => {
        checkKycStatus(isLogged);
        let menuItemsList = [...menuItems.filter((each) => each.show)];
        if(isLogged && entityType === 'ifa') {
            menuItemsList = [...menuItemsList.filter((each) => each.link !== '/refer-and-earn')];
        }

        let sideBarItemsList = [
            ...sideBarNavItems?.filter((each) => each.show),
        ];

        if (!showReferral(auth)) {
            setSideBarItems(sideBarItemsList.filter((each) => each.id !== 3));
        } else {
            setSideBarItems(sideBarItemsList);
        }

        setMenu(menuItemsList);
    };

    const fetchRMDetails = async () => {
        const authenticated = await isAuthenticated(auth);
        if (authenticated) {
            const relationshipManagerResponse =
                await getInvestorRelationshipManagerDetails(auth.id);

            setRMDetails(
                relationshipManagerResponse?.entity
                    ?.crmRelationshipManagersContact
            );
        }
    };

    useEffect(() => {
        if (isLogged !== null) {
            handleCloseModal();
            fetchRMDetails();
            fetchUserData();
        }
    }, [isLogged]);

    const loginModal = useCallback(() => {
        setShowSignUp(false);
        setShowLoginModal(true);
    }, [isLogged, router]);

    const signUpModal = useCallback(() => {
        setShowSignUp(true);
        setShowSignUpModal(true);
    }, [isLogged, router]);

    const handleCloseModal = useCallback(() => {
        setShowLoginModal(false);
        setShowSignUpModal(false);
    }, [isLogged, router]);

    const loginOrSignupModal = useCallback(() => {
        if (showLoginModal) {
            return (
                <LoginModalV2
                    handleCloseModal={handleCloseModal}
                    showLoginModal={showLoginModal}
                    showSignUp={showSignUp}
                    setShowSignUp={setShowSignUp}
                />
            );
        }
        if (showSignUpModal) {
            return (
                <LoginModalV2
                    handleCloseModal={handleCloseModal}
                    showSignUpModal={showSignUpModal}
                    showSignUp={showSignUp}
                    setShowSignUp={setShowSignUp}
                />
            );
        }
        return null;
    }, [showLoginModal, showSignUpModal, isLogged]);

    const values = {
        isLogged,
        loginModal,
        signUpModal,
        loginOrSignupModal,
        logout: handleLogout,
        menuItems: menu,
        sideBarItems: isLogged ? sideBarItems : [],
        investor: investor?.investor,
        setInvestor,
        rmDetails,
        showRMDetails,
    };

    return (
        <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
    );
};

export default AuthContext;
