import { getInvestor } from '@services/investors.service';
import useAuthStore from '@store/useAuthStore';

export const investorService = {
    get: async (id, forceFetch = true, ctx = null) => {
        const store = useAuthStore;
        let investor = store.getState().investor;
        if (forceFetch || !investor) {
            // TODO: getInvestor should be done with separate api and other details should be fetched from other apis
            const investorResponse = await getInvestor(id, ctx);

            if (investorResponse.status) {
                investor = investorResponse.entity;
                // Save to Zustand store for future use
                store.setState({
                    ...store.getState(),
                    investor,
                });
            }
        }

        return investor;
    },
};
