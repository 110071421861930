import { Text } from '@atoms/index';
import { useRef, useState } from 'react';
import DesktopNavbar from '@components/Navbar/Desktop/Navbar';
import MobileNavbar from '@components/Navbar/Mobile/Navbar';
import useEventListener from '@hooks/useEventListner';
import { triggerCustomGaEvent } from '@helpers/miscelleanous';

const LoginButton = ({ onClick, className = '' }) => (
    <div className="cursor-pointer " onClick={onClick} id="headerLogIn" gtmtag="gtm-click-login-btn-in-header">
        <Text
            className={`${className
                ? className
                : 'cursor-pointer text-primary-500 px-6 py-2 rounded-full border-solid border-1 font-semibold border-primary-500 '
                }`}
            content={'Login'}
        />
    </div>
);

const SignupButton = ({ onClick }) => (
    <div className="cursor-pointer " onClick={onClick} id="headerSignUp" gtmtag="gtm-click-signup-btn-in-header">
        <Text
            onClick={onClick}
            className="px-6 py-2 font-semibold border-2 border-solid rounded-full text-basicWhite bg-primary-500 border-primary-500 whitespace-nowrap"
            content={'Sign up'}
        />
    </div>
);

function NavHeader({
    hideHeaderOnScroll = false,
    translucent = false,
    homePage = false,
}) {
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const lastScrollPosition = useRef(0);

    const onScroll = () => {
        const currentScrollPos = window.pageYOffset;
        setIsHeaderVisible(
            lastScrollPosition.current > currentScrollPos ||
            currentScrollPos <= 0
        );

        lastScrollPosition.current = currentScrollPos;
    };

    const [lastScrollPos, setLastScrollPos] = useState(0);
    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const visible =
            lastScrollPos > currentScrollPos || currentScrollPos === 0;
        setLastScrollPos(currentScrollPos);
    };

    useEventListener('scroll', handleScroll);
    hideHeaderOnScroll && useEventListener('scroll', onScroll);

    const handleHeaderItemsClick = (gtmTriggerName) => {
        if(gtmTriggerName) {
            triggerCustomGaEvent(gtmTriggerName);
        };
    };

    return (
        <div
            id="sticky-header"
            className={`fixed ${!isHeaderVisible ? 'slide-up' : ''
                } flex flex-col items-center justify-center !m-0 h-16  navbar-sticky bg-basicWhite ${translucent ? 'bg-opacity-25' : ''
                } left-0 z-100 w-full`}
        >
            {/* Mobile and Tablet view */}
            <MobileNavbar
                LoginButton={LoginButton}
                SignupButton={SignupButton}
                homePage={homePage}
                handleHeaderItemsClick={handleHeaderItemsClick}
            />
            {/* desktop view */}
            <DesktopNavbar
                LoginButton={LoginButton}
                SignupButton={SignupButton}
                homePage={homePage}
                handleHeaderItemsClick={handleHeaderItemsClick}
            />
        </div>
    );
}

export default NavHeader;
