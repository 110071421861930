import AuthContext from '@context/auth';
import { toTitleCase } from '@helpers/miscelleanous';
import useEventListener from '@hooks/useEventListner';
import HamburgerMenuItem from '@molecules/menu/HamburgerMenuItem';
import { getAuth } from '@services/identity.service';
import useAuthStore from '@store/useAuthStore';
import { LazyImage, Link } from '@ui/atoms';
import { useClickAway, useWindowSize } from '@uidotdev/usehooks';
import { useContext, useState } from 'react';

const MobileNavbar = ({ LoginButton, SignupButton, homePage, handleHeaderItemsClick }) => {
    const auth = getAuth();
    const { isLogged } = useAuthStore();
    const { height } = useWindowSize();

    const {
        loginModal,
        signUpModal,
        logout,
        menuItems: menu,
        rmDetails,
        showRMDetails,
    } = useContext(AuthContext);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const ref = useClickAway(() => {
        setMenuOpen(false);
    });

    useEventListener('scroll', () => {
        setMenuOpen(false);
    });

    return (
        <div
            className="flex items-center justify-between w-full xl:hidden"
            ref={ref}
        >
            <div className="flex items-center justify-between w-full px-5 md:px-8">
                <div className="flex items-center justify-between w-full">
                    <Link href="/">
                        <LazyImage
                            src={'/images/logo.svg'}
                            className="w-[120px] h-7 relative"
                            alt="logo"
                        />
                    </Link>
                </div>
                <div className="flex items-center gap-x-6 md:gap-x-8">
                    {!isLogged && (
                        <>
                            {!menuOpen ? (
                                <div className="flex items-center md:hidden gap-x-2">
                                    <LoginButton
                                        onClick={() => {
                                            loginModal();
                                        }}
                                        className="cursor-pointer text-primary-500 px-6 py-[7px] rounded-full border-solid border-1 font-medium border-primary-500FF border-primary-500"
                                    />
                                </div>
                            ) : null}

                            <div className="items-center hidden md:flex gap-x-2">
                                <LoginButton
                                    onClick={() => {
                                        loginModal();
                                    }}
                                />
                                <SignupButton
                                    onClick={() => {
                                        signUpModal();
                                    }}
                                />
                            </div>
                        </>
                    )}
                    <button
                        onClick={handleMenu}
                        className="outline-none mobile-menu-button"
                    >
                        <LazyImage
                            src={
                                !menuOpen
                                    ? '/images/homepage/bar.svg'
                                    : '/images/close-hamburger.svg'
                            }
                            alt={!menuOpen ? 'bar' : 'close'}
                            className="relative w-5 h-4"
                        />
                    </button>
                </div>
                <div
                    className={`${menuOpen
                            ? 'mobile-menu-visible block'
                            : 'mobile-menu-hidden hidden'
                        } fixed top-16 right-0 left-0 md:left-auto z-50 mobile-menu min-width-full md:w-[350px]`}
                >
                    <div
                        className="relative flex flex-col justify-between w-full pb-20 bg-basicWhite md:w-full backdrop-blur-lg mobile-menu-content"
                        style={{ height: height }}
                    >
                        <ul className="px-5 overflow-y-auto">
                            {isLogged && (
                                <li key="User">
                                    <HamburgerMenuItem
                                        isUser={true}
                                        logout={() => logout(true)}
                                        closeMenu={() => setMenuOpen(false)}
                                        menuItem={{
                                            name: toTitleCase(auth?.name),
                                            submenu: [
                                                {
                                                    name: 'Profile',
                                                    link: '/profile',
                                                    icon: '/images/UserProfile.svg',
                                                    gtmtag: 'gtm-click-profile'
                                                },
                                                {
                                                    name: 'Logout',
                                                    link: '/logout',
                                                    icon: '/images/Logout.svg',
                                                    gtmtag: 'gtm-click-logout-btn'
                                                },
                                            ],
                                        }}
                                        showRMDetails={showRMDetails}
                                        rmDetails={rmDetails}
                                    />
                                </li>
                            )}
                            {menu
                                ?.filter((each) => each !== null)
                                ?.filter((each) => {
                                    if (
                                        isLogged &&
                                        each?.showWhenLoggedIn &&
                                        (homePage || !each?.showOnHomePageOnly)
                                    ) {
                                        return each;
                                    } else if (
                                        !isLogged &&
                                        each?.showWhenNotLoggedIn
                                    ) {
                                        return each;
                                    }
                                })
                                .map((item) => {
                                    {
                                        return (
                                            <li key={item.name}>
                                                <HamburgerMenuItem
                                                    menuItem={item}
                                                    closeMenu={() =>
                                                        setMenuOpen(false)
                                                    }
                                                    isLogged={isLogged}
                                                    handleHeaderItemsClick={handleHeaderItemsClick}
                                                />
                                            </li>
                                        );
                                    }
                                })}
                        </ul>
                        {!isLogged && (
                            <div className="flex items-center justify-center gap-4 py-6 bg-basicWhite md:hidden">
                                <LoginButton
                                    onClick={() => {
                                        loginModal();
                                    }}
                                />
                                <SignupButton
                                    onClick={() => {
                                        signUpModal();
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileNavbar;
