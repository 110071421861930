export const kycFlowGaEventsName = {
    SKIP_NOMINEE_IN_KYC: 'skipNomineeInKyc',
    ADD_NOMINEE_IN_KYC: 'addNomineeInKyc',
    PROCEED_ESIGN_KYC_DOCUMENT: "proceesEsignKycDocument",
    AADHAR_ESIGN_EVENT: "aadharEsignEvent",
    CAPTURE_DIGITAL_SIGNATURE: "captureDigitalSignature",
    GENERATE_KYC_DOCUMENT: "generateKycDocument",
    LAUNCH_HV_LIVE_PHOTO_SDK: "launchHyperVergeLivePhotoSdk",
    LIVE_PHOTO_VERIFIED: "livePhotoVerified",
    SUBMIT_BANK_DETAILS: "submitBankDetails",
    SUBMIT_DEMAT_ACCOUNT: "submitDematAccount",
    ACCEPT_BASIC_DETAILS: "acceptBasicDetails",
    ACCEPT_DIGI_LOCKER_CONSENT: "acceptDigiLockerConsent",
    GET_PAN_DETAILS_FROM_HYPERVERGE: "getPanDetailsFromHyperVerge",
    LAUNCH_HV_PAN_SDK: "launchHyperVergePanSdk",
    SUBMIT_PAN: "submitPan",
    SUBMIT_PERSONAL_DETAILS: "submitPersonalDetails",
    DIGI_LOCKER_VERIFIED_EVENT: "digiLockerVerifiedEvent",
    KYC_VERIFIED: "kycVerified",
    KYC_MANUAL_VERIFICATION: "kycManualVerification",
    HV_BANK_VERIFICATION: "hyperVergeBankVerification",
    LAUNCH_HV_BANK_SDK: "launchHyperVergeBankSdk",
    HV_BANK_RETRY: "hyperVergeBankRetry",
    EXIT_HV_BANK_VERIFICATION: "exitHyperVergeBankVerification",
    START_KYC_FROM_NUDGE: "startKycFromNudge",
    SKIP_KYC_FOR_NOW: "skipKycForNow"
}

export const profileGaEventsName = {
    ADD_ANOTHER_BANK_ACCOUNT: "addAnotherBankAccount",
    ADD_AND_UPDATE_NOMINEE_IN_PROFILE: 'addAndUpdateNomineeInProfile'
}

export const investmentFlowGaEventsName = {
    START_INVESTMENT: "startInvestment",
    DOWNLOAD_ORDER_SHEET_FROM_INVESTMENT_OVERVIEW: "downloadOrderSheetFromInvestmentOverview",
    CONTINUE_TO_PAYMENT_PAGE: "continueToPaymentpage",
    CONTINUE_TO_AMO_CONFIRMATION_PAGE: "continueToAmoConfirmationPage",
    PAY_LATER_FROM_FAILED_PAYMENT: "payLaterFromFailedPayment",
    RETRY_PAYMENT: "retryPayment",
    VIEWED_PAYMENT_SUCCESS: "viewedPaymentSuccess",
    VIEWED_PAYMENT_IN_PROGRESS : "viewedPaymentInProgress",
    VIEWED_PAYMENT_FAILED: "viewedPaymentFailed",
    CONTINUE_PAYMENT_WITH_NEFT_OR_RTGS: "continuePaymentWithNeftOrRtgs",
    CONTINUE_PAYMENT_WITH_NET_BANKING: "continuePaymentWithNetBanking",
    CONTINUE_PAYMENT_WITH_UPI: "continuePaymentWithUpi",
    VERIFY_UPI_PAYMENT_ID: "verifyUpiPaymentId",
    EXIT_PAYMENT_SELECTION_PAGE: "exitPaymentSelectionPage",
    CONTINUE_PAYMENT_LINK_FROM_PENDING_ORDERS: "continuePaymentLinkFromPendingOrders",
    VIEWED_AMO_CONFIRMATION: "viewedAmoConfirmation",
    PAYMENT_LINK_VIEWED_FROM_MAIL_OR_WHATSAPP: "paymentLinkViewedFromMailOrWhatsapp",
    VIEWED_ORDER_DETAILS_FROM_PAYMENT_SUCCESS : "viewedOrderDetailsFromPaymentSuccess",
    VIEWED_ORDER_DETAILS_FROM_PAYMENT_IN_PROGRESS: "viewedOrderDetailsFromPaymentInProgress",
    VIEWED_ORDER_CONFIRM : "viewedOrderConfirm",
    VIEWED_ORDER_FAILED: "viewedOrderFailed",
    CLICKED_TERMS_AND_CONDITIONS_INVESTMENT_OVERVIEW: "clickedTermsAndConditionsInvestmentOverview",
    CLICKED_AMO_CASHFREE_RETRY_PAYMENT : "clickedAmoCashfreeRetryPayment",
    CLICKED_AMO_CASHFREE_VIEW_ALL_ORDERS : "clickedAmoCashfreeViewAllOrders",
    CLICKED_AMO_OVERVIEW_VIEW_MORE_OPPORTUNITY : "clickedAmoOverviewViewMoreOpportunity",
    VIEW_AMO_CASHFREE_PAYMENT_IN_PROGRESS_PAYMENT_PAGE : "viewAmoCashFreePaymentInProgressPaymentPage",
    VIEW_AMO_CASHFREE_SUCCESS_PAYMENT_PAGE  : "viewAmoCashFreeSuccessPaymentPage",
    VIEW_AMO_CASHFREE_FAILED_PAYMENT_PAGE : "viewAmoCashFreeFailedPaymentPage",
    CLICKED_AMO_CASHFREE_CONFIRM_PAYMENT : "clickedAmoCashFreeConfirmPayment",
}

export const loginSignupEventsName = {
   SIGNUP: "signupEvent",
   LOGIN: "loginEvent",
   SIGNUP_OTP: "verifyOtpSignup",
   LOGIN_OTP: "verifyOtplogin"
}

export const triggerOnPage = {
    PENDING_ORDERS:"pending_orders_page",
    CASHFREE_AMO_PAYMENT_FAIL:"cashfree_amo_payment_fail_page",
};

export const WelcomeQuestionnaireEventsName = {
    VIEWED_QUESTIONNAIRE: 'Viewed_Questionnaire_Page',
    CLICKED_QUESTIONNAIRE_CONTINUE: 'Click_Questionnaire_Continue',
    CLICKED_QUESTIONNAIRE_SKIP: 'Click_Questionnaire_Skip',
    VIEWED_WELCOME_PAGE: 'Viewed_Welcome_Page',
    CLICKED_BOOK_CONSULTATION_WELCOME_PAGE:
        'clicked_book_consultation_welcomepage',
    CLICKED_START_KYC_WELCOME_PAGE: 'clicked_startKYC_welcomepage',
    CLICKED_SKIP_WELCOME_PAGE: 'clicked_skip_welcomepage',
    VIEWED_WELCOME_BACK_PAGE: 'viewed_welcomeback',
    CLICKED_BOOK_CONSULTATION_WELCOME_BACK_PAGE:
        'clicked_book_consultation_welcomeback',
    CLICKED_START_KYC_WELCOME_BACK_PAGE: 'clicked_startKYC_welcomeback',
    CLICKED_COMPLETE_KYC_WELCOME_BACK_PAGE: 'clicked_completeKYC_welcomeback',
    CLICKED_SKIP_WELCOME_BACK_PAGE: 'clicked_skip_welcomeback',
};

export const dashboardGaEventsName = {
    CLICKED_MY_REFERRAL_SIDEBAR: "clickedMyReferralSidebar",
    CLICKED_COPY_MY_REFERRAL_RNE: 'clickedCopyMyReferralRne',
    CLICKED_INVEST_NOW_MY_REFERRAL_PAGE: 'clickedInvestNowMyReferralPage',
    CLICKED_CONTACT_SUPPORT_MY_REFERRAL_PAGE: 'clickedContactSupportMyReferralPage',
    CLICKED_COMPLETE_KYC_MY_REFERRAL_PAGE: 'clickedCompleteKycMyReferralPage'
}

export const footerGaEventsName = {
    REFER_AND_EARN_FOOTER: "referAndEarnFooter",
}

export const headerGaEventsName = {
    REFER_AND_EARN_LOGIN: "referAndEarnLogin",
    REFER_AND_EARN_LOGOUT: 'referAndEarnLogout'
}

export const referAndEarnGaEventsName = {
    CLICKED_COPY_REFERRAL_LINK: "clickedCopyReferralLinkRne",
    CLICKED_SIGNUP_TO_EARN_REWARD: 'clickedSignupToEarnreward'
}
