import { Text } from '@atoms/index';
import { AnimatePresence, motion } from 'framer-motion';

const OpportunityOverview = ({ activeButton, filter, ...property }) => {
    const { stats } = property;
    const tabs = [
        {
            id: 'open',
            value: 'Active',
            gtmtag: 'gtm-click-active-btn'
        },
        {
            id: 'closed',
            value: `Recently Closed ${stats?.recentlyClosedDeals > 0
                ? '(' + stats?.recentlyClosedDeals + ')'
                : ''
                }`,
            gtmtag: 'gtm-click-recently-closed-btn'
        },
    ];

    const opacity = {
        hidden: {
            opacity: 0,
            zIndex: -100,
            x: -100,
        },
        visible: {
            opacity: 1,
            zIndex: 5,
            transition: {
                duration: 0.1,
                x: 0,
            },
        },
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.1,
            },
        },
    };

    return (
        <div className="inline-flex flex-col items-center justify-start w-full gap-4">
            <div className="flex flex-col items-center justify-start gap-2">
                <Text
                    content="Opportunities"
                    className="text-center text-primary-500 h3-bold"
                />
                <Text
                    content="Invest in curated, secured, high-yield Bonds"
                    className="pt-2 mx-1 text-gray-600 p5-regular"
                />
            </div>
            <div className="inline-flex items-start justify-start p-1 overflow-hidden bg-white border border-gray-300 rounded-3xl">
                <AnimatePresence mode="wait">
                    {tabs.map((tab) => {
                        const { id, value, gtmtag } = tab;
                        return (
                            <li
                                key={id}
                                className={`list-none cursor-pointer px-6 py-2 ${id === filter ? 'relative' : 'static'
                                    }`}
                                onClick={() => activeButton(id)}
                                gtmtag={gtmtag}
                            >
                                <Text
                                    content={value}
                                    className={`relative z-10  ${id === filter
                                        ? 'text-basicWhite p4-semibold'
                                        : 'text-primary-500 p4-medium'
                                        }`}
                                />
                                {id === filter && (
                                    <motion.div
                                        className="absolute top-0 rounded-full bottom-0 right-0 left-0 -w-full h-full z-[5] bg-primary-500"
                                        layoutId="underline"
                                        variants={opacity}
                                        initial="hidden"
                                        animate="visible"
                                        exit="exit"
                                    />
                                )}
                            </li>
                        );
                    })}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default OpportunityOverview;
