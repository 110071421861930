import { useClickAway } from '@uidotdev/usehooks';
import { motion } from 'framer-motion';
import Image from 'next/legacy/image';
import { animations } from './utils/helper';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import useToggleBodyOverflow from '@ui/hooks/useToggleBodyOverflow';

const Modal = ({
    show,
    onClose,
    label,
    width,
    height,
    hideTitle,
    children,
    labelSize,
    id,
    labelPadding,
    animation,
    mainWrapperClassName = '',
    canCloseOuter = true,
    roundedClass = 'rounded-lg md:rounded-2xl',
    paddingClass = 'p-6',
    isError = false,
    labelClassName = 'font-semibold font-bahnschrift text-gray-800',
    ...property
}) => {
    useToggleBodyOverflow(show);
    const yScroll = height ? `overflow-y-auto ${height}` : 'overflow-y-auto';
    const labelFontSize = labelSize ? labelSize : 'text-xl md:text-2xl';

    const modalRef = useClickAway(() => {
        if (canCloseOuter) onClose();
    });

    return (
        <div
            className={`fixed top-0 left-0  items-center justify-center w-full h-full modal  z-100  p-4 ${
                !show ? 'hidden' : 'flex !mt-0'
            } ${mainWrapperClassName}`}
        >
            <motion.div
                variants={animations.opacity}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="absolute z-40 w-full h-full bg-gray-900 !opacity-50 modal-overlay"
            ></motion.div>

            <motion.div
                variants={animation && animations[animation]}
                initial="hidden"
                animate="visible"
                exit="exit"
                ref={modalRef}
                className={`text-basicBlack bg-basicWhite w-full m-2 md:mx-0 ${width}  ${roundedClass} shadow-lg z-50 ${height} flex flex-col`}
            >
                {!hideTitle && (
                    <div
                        className={`flex items-center justify-between border-b ${
                            labelPadding ? labelPadding : 'p-5'
                        } `}
                    >
                        <div className="flex items-center gap-x-2">
                            {isError && (
                                <ExclamationCircleIcon className="w-[28px] h-[28px] text-semantic-error-base" />
                            )}
                            <p
                                className={`${labelClassName}  ${labelFontSize}`}
                            >
                                {label}
                            </p>
                        </div>
                        {onClose && (
                            <div
                                className={`z-50 cursor-pointer modal-close ${property.crossButtonClass}`}
                                onClick={onClose}
                                id={id}
                            >
                                <Image
                                    src="/images/close-hamburger.svg"
                                    width="20"
                                    height="20"
                                />
                            </div>
                        )}
                    </div>
                )}
                <div
                    className={`${paddingClass} ${property.className} ${yScroll}`}
                >
                    {children}
                </div>
            </motion.div>
        </div>
    );
};

export default Modal;
