import {useState} from 'react';
import {Button, Image} from '@atoms/index';
import {downloadRealisedIRRDetails} from '@services/investments.service';
import {downloadUrl} from '@helpers/miscelleanous';
import FullPageWaitAnimation from '@components/common/FullPageWaitAnimation';

const DownloadRealisedIRRDetails = ({
                                        investmentId,
                                        buttontext = 'download realised IRR details',
                                        newUI = false,
                                    }) => {
    const [isDownloading, setDownloading] = useState(false);
    const toggleDownloading = () => setDownloading((prevState) => !prevState);
    const downloadDetails = async () => {
        try {
            toggleDownloading();
            const response = await downloadRealisedIRRDetails(
                investmentId
            );
            if (response?.entity) {
                await downloadUrl(response.entity.location);
            }
            toggleDownloading();
        } catch (e) {
            throw e;
        }
    };
    return isDownloading ? (
        <div style={{zoom: '1.25'}}>
            <FullPageWaitAnimation
                text="Please wait we are collecting all the download. The process might take longer."/>
        </div>
    ) : (
        <Button
            design="standard"
            type="ghost"
            children={
                <>
                    <Image
                        src="/images/download-icon.svg"
                        className="pr-2 "
                        alt="view"
                    />
                    {
                        <span
                            class="inline-block truncate text-sm md:text-base"
                            title={`${buttontext}`}
                        >
                            {buttontext}
                        </span>
                    }
                </>
            }
            paddingClass="mx-0"
            className={`flex items-center !justify-start w-full mb-0 p3-underline rounded mt-0 ${
                newUI ? 'border-none px-0 py-0' : ''
            }`}
            onClick={() => !isDownloading && downloadDetails()}
        />
    );
};
export default DownloadRealisedIRRDetails;
