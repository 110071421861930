const Card = ({
    children,
    paddingClass = 'p-5',
    verticalSpace = 'space-y-4',
    onClick = () => { },
    ...property
}) => {
    return (
        <div
            className={`items-center shadow-lg rounded-xl bg-basicWhite ${property.className} ${paddingClass}`}
            style={{ boxShadow: property.boxShadow ? property.boxShadow : '' }}
            onClick={onClick}
        >
            <div className={verticalSpace}>{children}</div>
        </div>
    );
};

export default Card;
