import Cookies from 'universal-cookie';
import { getWallet } from './jiraaf-safe.service';
import jwtDecode from 'jwt-decode';
import { refreshToken } from '@services/http.service';
import { getAuthCtx, setCookies } from '@http/ssr-http.service';

const cookies = new Cookies();

// TODO - Refactor the way we get auth post refresh tokens
export const getAuth = () => {
    return cookies.get('AUTH');
};

// TODO - Refactor the way we set auth post refresh tokens
export const setAuth = (authObject) => {
    cookies.set('AUTH', JSON.stringify(authObject), { path: '/' });
    return authObject;
};
export const getCookies = (cookieName) => {
    return cookies.get(cookieName);
};
export const storeCookies = (cookieName, cookieObject) => {
    cookies.set(cookieName, JSON.stringify(cookieObject), { path: '/' });
    return cookieObject;
};

export const removeAuth = () => {
    return cookies.remove('AUTH', { path: '/' });
};

export const isAuthenticated = async (user, ctx = null) => {
    return user != null && user.token && (await verifyToken(ctx));
};

export const isUnauthorizedRequest = async (auth, ctx = null) => {
    return !auth || !(await isAuthenticated(JSON.parse(auth), ctx));
};

export const getReferralCode = () => {
    return cookies.get('REFERRAL_CODE');
};

export const getReferralEntityType = () => {
    return cookies.get('REFERRAL_ENTITY');
};

export const setReferralCode = (ReferralObject) => {
    cookies.set('REFERRAL_CODE', JSON.stringify(ReferralObject), { path: '/' });
    return ReferralObject;
};

export const setReferralEntityType = (ReferralObject) => {
    cookies.set('REFERRAL_ENTITY', JSON.stringify(ReferralObject), { path: '/' });
    return ReferralObject;
};

export const removeReferralCode = () => {
    return cookies.remove('REFERRAL_CODE', { path: '/' });
};

export const removeReferralEntityType = () => {
    return cookies.remove('REFERRAL_ENTITY', { path: '/' });
};

// To check if token is valid or not
// Endpoint to the check.
// decode the token, if ext is greater than 30 seconds of now. -> true else false
// Al calls to backend.
export const verifyToken = async (ctx) => {
    try {
        if (ctx) {
            let currentTimestamp = new Date().getTime() / 1000;
            const authCtx = getAuthCtx(ctx);
            const decoded = jwtDecode(authCtx.token);
            const isExpired = decoded.exp < currentTimestamp;
            if (isExpired) {
                const response = await refreshToken(authCtx);
                if (response.status) {
                    setCookies(ctx, { token: response.entity });
                }
                return response.status;
            }
            return !isExpired;
        } else {
            let currentTimestamp = new Date().getTime() / 1000;
            const auth = getAuth();
            const decoded = jwtDecode(auth.token);
            const isExpired = decoded.exp < currentTimestamp;
            if (isExpired) {
                const response = await refreshToken(auth);
                if (response.status) {
                    setAuth(response.entity);
                }
                return response.status;
            }
            return !isExpired;
        }
    } catch (e) {
        return false;
    }
};

export const isAdmin = getAuth()?.role === 'Admin';
export const isSuperAdmin = () =>
    isAdmin && process.env.NEXT_PUBLIC_SA.includes(getAuth().username);

export const getLogoutSsr = (ctx, redirectUrl = '') => {
    /* 
        ctx.res.setHeader('Cache-Control', 'no-store') sets the Cache-Control header to 'no-store', 
        indicating that the response should not be stored or cached.
        By setting the cache control header in this way, you can prevent caching of the redirect response.
        Issue resolved for duplicate tab redirected to cache route
    */
    if (ctx) ctx.res.setHeader('Cache-Control', 'no-store');
    return {
        redirect: {
            permanent: false,
            destination: redirectUrl ? `/logout?redirect=${redirectUrl}` : `/logout`,
        },
    };
};

export const isWalletActive = async (investorId, ctx = null) => {
    if (investorId) {
        const wallet = await getWallet(investorId, ctx);
        return wallet?.entity?.isActive;
    }
    return false;
};
